function showDialog(title, single, callback) {
	single = single === void 0 ? true : single;
	callback = callback === void 0 ? function() {} : callback;
	
	$('html').append('<div class="dialogue_screen" id="dialogue_screen"></div>');
    var $dialogScreen =  $('#dialogue_screen');

	var url = single ? '/dialog/single/'+title : '/dialog/complex/'+title;
    $("html").css('overflow-y', 'hidden');
	$dialogScreen.load(url, function(){
		centerDialogue();
        callback();
	});
}

function hideDialog(){
	$("#dialogue_screen").fadeOut("fast").remove();
    $("html").css('overflow-y', 'scroll');
}

function centerDialogue() {
    var top = ($(window).height() - $(".dialogue_border").height() - 100) / 2;
    top = top < 0 ? 0 : top;
    $(".dialogue_border").css("margin-top", top + "px").show();
}

function dialogCallback(){
	showDialog("callback");
}

function dialogFeedback(type){
	showDialog("feedback/"+type);
}

function dialogLoginza(){
	showDialog("loginza");
}

function dialogRecoverPass(){
	showDialog("recover");
}

function dialogRegister(){
	showDialog("register");
}

function dialogLogin(){
	showDialog("login");
}

function logOut(){
	$.ajax({
		url:"/ajax/logout/",
		dataType:"script"
	});
}

function profileDropDown(){
	if($("#userLoginMenu").is(":visible")){
		$("#userLoginMenu").fadeOut();
	}else{
		$("#userLoginMenu").fadeIn();
	}
}

function alignFeedback(){
	$(".feedback").css("top",(($(window).height()-$(".feedback").height())/2)+"px").show();
}

function slideFeedback(){
	var e=$(".feedback");
	if($(e).offset().left==0){
		$(e).animate({left:-254}).css("box-shadow","none");
	}
	if($(e).offset().left==-254){
		$(e).animate({left:0}).css("border-radius","0px 12px 12px 0px").css("box-shadow","0px 1px 16px #888");
	}
}

function lazyBanners() {
    var $banners = $('.lazy_banner');
    for (var i = 0; i < $banners.length; i++) {
        var image = new Image();
        image.data_src = $banners[i].getAttribute('data-src');
        image.onload = function () {
            $('.lazy_banner[data-src="' + this.data_src + '"]')
                    .css('opacity', 0)
                    .css('background-image', 'url(' + this.src + ')')
                    .animate({opacity: 1}, 500);
        };
        image.src = $banners[i].getAttribute('data-src');
    }
}

function str_replace(search, replace, subject) {return subject.split(search).join(replace);}

function strpos(haystack, needle, offset){var i = haystack.indexOf( needle, offset ); return i >= 0 ? i : false;}

	window.sideBannerAnimate = false;
	window.intervalAnimate = 10000;

	$(document).ready(function(){
		alignFeedback();


		$(document).on('click', 'a[href="#"]', function(e) {
        	e.preventDefault();
    	});


		var imgHeight = $('.side_banners img').height();
		var availOffset = (imgHeight + 4) * $('.side_banners img').length - (imgHeight + 4) * 3 - 4;

		var interval_id = window.setInterval(function(){
			var offsetTop = parseInt($('.banners ul').css('top'));
			
			if (window.sideBannerAnimate == false) {
				window.sideBannerAnimate = true;
				offsetTop = offsetTop > -availOffset ? offsetTop - imgHeight * 3 - 2 * 4 : 0;

				$('.side_banners ul').animate({top: offsetTop}, 400, function(){
					window.sideBannerAnimate = false;
				});
			}
		}, window.intervalAnimate)

		$('#sb-top').click(function(e){
			e.preventDefault();
			var offsetTop = parseInt($('.banners ul').css('top'));
			if (offsetTop < 0 && window.sideBannerAnimate == false) {
				window.sideBannerAnimate = true;
				$('.side_banners ul').animate({top: offsetTop + imgHeight * 3 + 2 * 4}, 400, function(){
					window.sideBannerAnimate = false;
				});
			}
		})

		$('#sb-bottom').click(function(e){
			e.preventDefault();
			var offsetTop = parseInt($('.banners ul').css('top'));
			if (offsetTop > -availOffset && window.sideBannerAnimate == false) {
				window.sideBannerAnimate = true;
				$('.side_banners ul').animate({top: offsetTop - imgHeight * 3 - 2 * 4}, 400, function(){
					window.sideBannerAnimate = false;
				});
			}
		})
        
        lazyBanners();
	});


	$(window).resize(function(){
		alignFeedback();
        if ($(".dialogue_screen").length > 0) {
            centerDialogue();
        }
	});